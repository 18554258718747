import { ApolloProvider } from "@apollo/client";
import { ConfigProvider } from "antd";
import { createRoot } from "react-dom/client";
import { I18nextProvider, useTranslation } from "react-i18next";
import { BrowserRouter } from "react-router-dom";

import { initializeApollo } from "./apollo";
import App from "./App";
import { AuthProvider } from "./auth";
import { CartProvider } from "./components/Cart/CartContext";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import i18n from "./locales/i18n";
import { useEffect } from "react";

const MyApp = () => {
  const { i18n: translationI18n } = useTranslation(); // Destructure i18n as translationI18n to avoid conflicts
  const currentLanguage = translationI18n.language;

  useEffect(() => {
    if (currentLanguage === "ar") {
      document.dir = "rtl";
    } else {
      document.dir = "ltr";
    }
  }, [currentLanguage]);

  return (
    <I18nextProvider i18n={i18n}>
      <ConfigProvider
        direction={currentLanguage === "ar" ? "rtl" : "ltr"}
        theme={{
          token: {
            colorPrimary: "#35b4a7",
            fontFamily:
              currentLanguage === "ar" ? '"Noto Kufi Arabic", sans-serif' : '"Figtree", sans-serif',
          },
        }}
      >
        <BrowserRouter>
          <ApolloProvider client={initializeApollo()}>
            <AuthProvider>
              <CartProvider>
                <Navbar />
                <App />
                <Footer />
              </CartProvider>
            </AuthProvider>
          </ApolloProvider>
        </BrowserRouter>
      </ConfigProvider>
    </I18nextProvider>
  );
};

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(<MyApp />);
