import { Modal, Table } from "antd";

import { useTranslation } from "react-i18next";

const OrderDetailsModal = ({ isModalOpen, handleOk, handleCancel, orderObj }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const columns = [
    {
      dataIndex: "image",
      key: "image",
      render: (_, obj) => {
        const name = obj.product["name" + (currentLanguage === "en" ? "En" : "Ar")];
        return <img src={obj.product.picture} alt={name} style={{ width: 100, height: 100 }} />;
      },
    },
    {
      dataIndex: "name",
      key: "name",
      render: (_, obj) => obj.product["name" + (currentLanguage === "en" ? "En" : "Ar")],
    },
    {
      dataIndex: "price",
      key: "price",
      render: (_, obj) => (
        <span style={{ fontFamily: '"Figtree", sans-serif' }}>
          {obj.product.price.toFixed(2) + " KD"}
        </span>
      ),
    },
    {
      dataIndex: "quantity",
      key: "quantity",
      render: (_, obj) => (
        <span style={{ fontFamily: '"Figtree", sans-serif' }}>{obj.quantity}</span>
      ),
    },
    {
      dataIndex: "total",
      key: "total",
      render: (_, obj) => (
        <span style={{ fontFamily: '"Figtree", sans-serif' }}>
          {(obj.product.price * obj.quantity).toFixed(2) + " KD"}
        </span>
      ),
    },
  ];

  return (
    <Modal
      title={t("dashboard.ordersTable.orderDetailsModal.title")}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      key={orderObj.id}
      width={800}
      footer={null}
    >
      <Table
        rowKey="id"
        columns={columns}
        dataSource={orderObj.orderProducts}
        pagination={false}
        showHeader={false}
        scroll={{ x: true }}
      />
    </Modal>
  );
};

export default OrderDetailsModal;
