import styled from "styled-components";

const NavbarStyles = styled.div`
  margin-bottom: 20px;

  .ant-btn svg {
    margin-right: 0;
  }

  .ant-layout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background: #fff;

    ul {
      border-bottom: none;
    }
  }

  .ant-menu-item::after,
  .ant-menu-submenu-title::after {
    content: none !important;
    border-bottom: none !important;
  }

  ul.ant-menu.ant-menu-root.ant-menu-vertical {
    border-inline-end: none !important;
  }
`;

export default NavbarStyles;
