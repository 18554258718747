import { useMutation, useQuery } from "@apollo/client";
import { Button, Col, Form, Input, Layout, Row, Typography } from "antd";
import ApplicationPanelFormContainer from "components/ApplicationPanel/styles";
import { Loading } from "components/Loading";
import { UPDATE_ABOUT_SECTION_MUTATION } from "graphql/manageLandingPage/mutations";
import { GET_ABOUT_SECTION_DETAILS } from "graphql/queries";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { About, GetAboutData, UpdateAboutSectionResponse } from "types/Home";

const { Title } = Typography;
const { Content } = Layout;

const AboutSectionForm = () => {
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const [ourStoryImage, setOurStoryImage] = useState<File | undefined>(undefined);
  const [ourTeamPDF, setOurTeamPDF] = useState<File | undefined>(undefined);

  const errorMessageRef = useRef<HTMLDivElement>(null);
  const successMessageRef = useRef<HTMLDivElement>(null);

  const {
    loading: aboutSectionDetailsLoading,
    data: aboutSectionDetailsData,

    refetch: AboutSectionDetailsRefetch,
  } = useQuery<GetAboutData>(GET_ABOUT_SECTION_DETAILS);

  const [updateAboutSectionMutation] = useMutation(UPDATE_ABOUT_SECTION_MUTATION, {
    onCompleted: (response: UpdateAboutSectionResponse) => {
      if ("updateAboutSection" in response && response.updateAboutSection) {
        setErrorMessage(null);
        setSuccessMessage(t("dashboard.savedSuccessfullyMessage"));
        scrollToSuccess();
        setTimeout(() => {
          setSuccessMessage(null);
        }, 5000); // Clear success message after 5 seconds
        AboutSectionDetailsRefetch();
      } else if ("errors" in response) {
        setErrorMessage(response.errors[0]);
        setSuccessMessage(null);
        scrollToError();
      }
    },
  });

  const onFinish = (values: About) => {
    updateAboutSectionMutation({
      variables: { ...values, ourStoryImage, ourTeamPdf: ourTeamPDF },
    });
  };

  // @ts-expect-error because we don't have they type ValidateErrorEntity exported from antd
  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line no-console
    console.log("Failed:", errorInfo);
  };

  const scrollToError = () => {
    if (errorMessageRef.current) {
      errorMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const scrollToSuccess = () => {
    if (successMessageRef.current) {
      successMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  if (aboutSectionDetailsLoading || !aboutSectionDetailsData?.aboutSectionDetails)
    return <Loading />;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ["ourStoryImage"]: _removedStoryImage,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ["ourTeamPdf"]: _removedTeamPDF,
    ...initialValue
  } = aboutSectionDetailsData?.aboutSectionDetails || {};

  return (
    <ApplicationPanelFormContainer>
      <Layout className="p-3 p-md-5 m-0">
        <Content>
          <Row gutter={[24, 0]}>
            <Col xs={24}>
              <Title
                className="mb-5 mt-0"
                style={{
                  fontSize: "45px",
                  whiteSpace: "break-spaces",
                }}
              >
                {t("dashboard.sideNav.aboutSectionTab")}
              </Title>
            </Col>
          </Row>

          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="row-col"
            scrollToFirstError={true}
            initialValues={initialValue}
          >
            <div ref={errorMessageRef}></div> {/* Error message ref */}
            {errorMessage && <p className="text-danger english-field">{errorMessage}</p>}
            <div ref={successMessageRef}></div> {/* Success message ref */}
            {successMessage && <p className="text-success">{successMessage}</p>}
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item
                  label={t("dashboard.landingPageSettings.aboutSection.ourStoryInEnglish")}
                  name="ourStoryEn"
                  rules={[
                    {
                      required: true,
                      message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                    },
                  ]}
                >
                  <Input.TextArea rows={5} className="english-field" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item
                  label={t("dashboard.landingPageSettings.aboutSection.ourStoryInArabic")}
                  name="ourStoryAr"
                  rules={[
                    {
                      required: true,
                      message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                    },
                  ]}
                >
                  <Input.TextArea rows={5} className="arabic-field" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item
                  label={t("dashboard.landingPageSettings.aboutSection.ourProgramInEnglish")}
                  name="ourProgramEn"
                  rules={[
                    {
                      required: true,
                      message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                    },
                  ]}
                >
                  <Input.TextArea rows={5} className="english-field" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item
                  label={t("dashboard.landingPageSettings.aboutSection.ourProgramInArabic")}
                  name="ourProgramAr"
                  rules={[
                    {
                      required: true,
                      message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                    },
                  ]}
                >
                  <Input.TextArea rows={5} className="arabic-field" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item
                  label={t("dashboard.landingPageSettings.aboutSection.ourTeamInEnglish")}
                  name="ourTeamEn"
                  rules={[
                    {
                      required: true,
                      message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                    },
                  ]}
                >
                  <Input.TextArea rows={5} className="english-field" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item
                  label={t("dashboard.landingPageSettings.aboutSection.ourTeamInArabic")}
                  name="ourTeamAr"
                  rules={[
                    {
                      required: true,
                      message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                    },
                  ]}
                >
                  <Input.TextArea rows={5} className="arabic-field" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={
                    <>
                      {t("dashboard.landingPageSettings.aboutSection.ourStoryImage")}
                      &nbsp;
                      <a
                        href={aboutSectionDetailsData?.aboutSectionDetails?.ourStoryImage}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ({t("dashboard.landingPageSettings.aboutSection.viewCurrentImage")})
                      </a>
                    </>
                  }
                  name="ourStoryImage"
                  rules={[
                    {
                      message: t("auth.signUp.requiredFieldMessage"),
                    },
                  ]}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      if (event.target.files && event.target.files.length > 0)
                        setOurStoryImage(event.target.files?.[0]);
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  label={
                    <>
                      {t("dashboard.landingPageSettings.aboutSection.ourTeamPDF")}
                      {aboutSectionDetailsData?.aboutSectionDetails?.ourTeamPdf && (
                        <>
                          &nbsp;
                          <a
                            href={aboutSectionDetailsData?.aboutSectionDetails?.ourTeamPdf}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            ({t("dashboard.landingPageSettings.aboutSection.viewCurrentFile")})
                          </a>
                        </>
                      )}
                    </>
                  }
                  name="ourTeamPDF"
                  rules={[
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    ({ getFieldValue }) => ({
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      validator(_, value) {
                        if (!ourTeamPDF) return Promise.resolve();

                        if (ourTeamPDF.type !== "application/pdf")
                          return Promise.reject(
                            new Error(t("registrationForm.stepTwo.helpText.onlyPDF")),
                          );

                        if (ourTeamPDF.size > 1024 * 1024)
                          return Promise.reject(
                            new Error(t("registrationForm.stepTwo.helpText.fileSize")),
                          );

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <input
                    type="file"
                    accept="application/pdf"
                    onChange={(event) => {
                      if (event.target.files && event.target.files.length > 0)
                        setOurTeamPDF(event.target.files?.[0]);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                    {t("dashboard.applicationPanelForm.saveBtn")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Content>
      </Layout>
    </ApplicationPanelFormContainer>
  );
};

export default AboutSectionForm;
