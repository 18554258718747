import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Col, Flex, Row, Button } from "antd";
import { FilterOutlined } from "@ant-design/icons";

import SearchBar from "components/inputs/SearchBar";

import { ORDERS_LIST } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";

import OrdersTable from "./OrdersTable";
import FilterModal from "./FilterModal";

const OrdersDashboard = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [searchQuery, setSearchQuery] = useState("");
  const [filterQuery, setFilterQuery] = useState("");
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);

  const {
    loading: ordersListDataLoading,
    data: ordersListData,
    refetch: refetchOrdersListData,
  } = useQuery(ORDERS_LIST, {
    variables: { filters: filterQuery, search: searchQuery },
  });

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <Flex
        vertical
        gap="middle"
        style={{
          backgroundColor: "white",
          padding: 24,
        }}
      >
        <Row className="searchbar-row">
          <Col xs={24} lg={16}>
            <SearchBar
              placeholder={t("dashboard.ordersTable.searchPlaceholder")}
              value={searchQuery}
              onChange={handleChange}
            />
          </Col>

          <Col xs={24} lg={8} style={{ textAlign: currentLanguage === "ar" ? "left" : "right" }}>
            <Button
              type="primary"
              shape="circle"
              icon={<FilterOutlined />}
              className="filter-btn"
              onClick={() => setFilterModalOpen(true)}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <OrdersTable
              data={ordersListData?.ordersList || []}
              loading={ordersListDataLoading}
              refetchData={refetchOrdersListData}
            />
          </Col>
        </Row>
      </Flex>

      <FilterModal
        isModalOpen={isFilterModalOpen}
        handleOk={() => setFilterModalOpen(false)}
        handleCancel={() => setFilterModalOpen(false)}
        setFilterQuery={setFilterQuery}
      />
    </>
  );
};

export default OrdersDashboard;
