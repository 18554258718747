import styled from "styled-components";

const PictureAlbumContainer = styled.div`
  .ant-layout {
    background: #fff;
  }

  .ant-carousel {
    width: 100%;
  }

  .slick-slide.slick-active.slick-current,
  .slick-slide.slick-active {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100%;
    width: 100%;
  }

  .carousel-image {
    width: 450px;
    height: 450px;
    border-radius: 10px;
    margin: 5px;
    object-fit: cover;
    background-color: #fff;
  }

  .carousel-card {
    width: 360px;
    height: 450px;
    border-radius: 10px;
    margin: 5px;
    object-fit: cover;
    background-color: #35b4a7;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

export default PictureAlbumContainer;
