import { gql } from "@apollo/client";

export const GET_HERO_SECTION_DETAILS = gql`
  query GetHeroSectionDetails {
    heroSectionDetails {
      id
      image
      titleEn
      titleAr
      visionEn
      visionAr
      missionEn
      missionAr
    }
  }
`;

export const GET_TEAM_SECTION_DESCRIPTION = gql`
  query GetTeamSectionDescription {
    aboutSectionDetails {
      id
      ourTeamPdf
      ourTeamEn
      ourTeamAr
    }
  }
`;

export const GET_TEAM_MEMBERS_LIST = gql`
  query GetTeamMembersList($active: Boolean) {
    teamMembersList(active: $active) {
      id
      fullNameAr
      fullNameEn
      jobTitleAr
      jobTitleEn
      image
      active
    }
  }
`;

export const GET_PARTICIPATING_ENTITIES_LIST = gql`
  query GetParticipatingEntitiesList($active: Boolean) {
    participatingEntitiesList(active: $active) {
      id
      name
      logo
      active
    }
  }
`;

export const GET_SPONSORS_LIST = gql`
  query GetSponsorsList($active: Boolean) {
    sponsorsList(active: $active) {
      id
      name
      logo
      active
    }
  }
`;

export const GET_FOOTER_SECTION_DETAILS = gql`
  query GetFooterSectionDetails {
    footerSectionDetails {
      id
      briefEn
      briefAr
      whatsappNumber
      email
    }
  }
`;

export const GET_SOCIAL_MEDIA_LIST = gql`
  query GetSocialMediaList($active: Boolean) {
    socialMediaList(active: $active) {
      id
      type
      url
      active
    }
  }
`;

export const GET_ALBUM_PICTURES_LIST = gql`
  query GetAlbumPicturesList($active: Boolean) {
    albumPicturesList(active: $active) {
      id
      picture
      descriptionAr
      descriptionEn
      title
      active
    }
  }
`;

export const GET_APPLICATION_PANEL_DATA = gql`
  query GetApplicationPanelData {
    applicationPanelData {
      id
      formType
      applicationLimit
      participationFees
      participantsAgeFrom
      participantsAgeTo
      malesWhatsappCommunityLink
      femalesWhatsappCommunityLink
      aboutProgram
      applicationRequirements
      requiredDocuments
      programRequirements
      generalNotes
    }
  }
`;

export const GET_INVOICE_DETAILS = gql`
  query GetInvoiceDetails($paymentId: String!) {
    invoiceDetails(paymentId: $paymentId) {
      invoiceId
      invoiceStatus
      invoiceReference
      createdDate
      invoiceValue
      invoiceDisplayValue
      customerName
      customerMobile
      customerEmail
      customerReference
      transactionDate
      paymentGateway
      referenceId
      trackId
      transactionId
      paymentId
      authorizationId
      transactionStatus
      transactionValue
      customerServiceCharge
      totalServiceCharge
      dueValue
      error
      errorCode
    }
  }
`;

export const PARTICIPANT_PROFILE_INFORMATION = gql`
  query participantProfile($userId: Int!) {
    participantProfile(userId: $userId) {
      participantId
      email
      fullNameEn
      fullNameAr
      civilIdNumber
      dateOfBirth
      gender
      mobileNumber
      guardianMobileNumber
      guardianRelation
      emergencyContactNumber
      emergencyContactRelation
      languages
      otherLanguages
      chronicDiseasesYesNo
      chronicDiseases
      nationality
      motherNationality
      countryOfResidence
      age
    }
  }
`;

export const REGISTRATION_QUESTION_ANSWERS = gql`
  query registrationQuestionAnswers($registrationId: ID!) {
    registrationQuestionAnswers(registrationId: $registrationId) {
      id
      questionId
      question
      answer
      createdAt
      updatedAt
    }
  }
`;

export const AVAILABLE_COMPANIES_LIST = gql`
  query availableCompaniesList($gender: String!) {
    availableCompaniesList(gender: $gender) {
      id
      companyNameAr
      companyNameEn
      status(gender: $gender)
      jobOpportunities {
        id
        jobTitle
        jobDescription
        startingTime
        endingTime
        location {
          address
        }
        status(gender: $gender)
      }
    }
  }
`;

export const PARTICIPANT_HISTORY_DETAILS = gql`
  query registrations($userId: ID!) {
    registrations(userId: $userId) {
      id
      status
      paymentStatus
      formType
      createdAt
      assignedJobOpportunity {
        jobTitle
        jobDescription
        startingTime
        endingTime
        location {
          address
          url
        }
        companyRequest {
          companyNameEn
          companyNameAr
        }
      }
    }
  }
`;

export const ATTENDANCE_RECORDS_LIST = gql`
  query attendanceRecords($filters: String, $search: String, $first: Int, $skip: Int) {
    attendanceRecords(filters: $filters, search: $search, first: $first, skip: $skip) {
      id
      date
      checkInTime
      checkOutTime
      checkInLatitude
      checkInLongitude
      checkOutLatitude
      checkOutLongitude
      registration {
        participantProfile {
          participantId
          fullNameAr
          fullNameEn
          mobileNumber
          email
        }
        status
        paymentStatus
        assignedJobOpportunity {
          jobTitle
          jobDescription
          companyRequest {
            companyNameEn
            companyNameAr
          }
        }
      }
    }
  }
`;

export const COMPANY_PROFILE_INFORMATION = gql`
  query companyProfile($userId: Int!) {
    companyProfile(userId: $userId) {
      email
      nameEn
      nameAr
      descriptionEn
      descriptionAr
      contactPersonName
      contactPersonNumber
      logo
    }
  }
`;

export const REQUESTS_LIST = gql`
  query requestsList($search: String, $first: Int, $offset: Int) {
    requestsList(search: $search, first: $first, offset: $offset) {
      totalCount
      edges {
        node {
          pk
          id
          createdAt
          requestedGender
          totalRequestedMales
          totalRequestedFemales
          totalAssignedMales
          totalAssignedFemales
          company {
            nameEn
            nameAr
            contactPersonName
            contactPersonNumber
          }
          jobOpportunities {
            jobTitle
            jobDescription
          }
        }
      }
    }
  }
`;

export const REQUEST_DETAILS = gql`
  query requestDetails($id: ID) {
    requestDetails(id: $id) {
      trainingType
      specialRequirements
      certificateCopy
      accurateInformation
      interviewers {
        id
        name
        number
      }
      locations {
        id
        address
        url
        supervisorName
        supervisorNumber

        jobOpportunities {
          id
          jobTitle
          jobDescription
          malesNumber
          femalesNumber
          startingTime
          endingTime
          workDays
          specialNotes
        }
      }
      jobOpportunities {
        id
        jobTitle
        jobDescription
        malesNumber
        femalesNumber
        startingTime
        endingTime
        workDays
        specialNotes
        location {
          address
          url
        }
      }
      assignedInterns {
        id
        status
        paymentStatus
        valuesYouBelieveIn
        howDidYouKnowAboutTheProgram
        mediaSkillsYesNo
        mediaSkills
        mediaFields
        civilId
        civilIdBase64
        personalImage
        personalImageBase64
        ibanNumber
        tshirtSize
        specialRequest
        programRequirements
        correctInformation
        formType
        assignedJobOpportunity {
          jobTitle
          companyRequest {
            companyNameEn
            companyNameAr
          }
        }
        questionAnswers {
          question
          answer
        }
        participantProfile {
          user {
            id
            email
          }
          participantId
          fullNameEn
          fullNameAr
          civilIdNumber
          dateOfBirth
          gender
          mobileNumber
          guardianMobileNumber
          guardianRelation
          emergencyContactNumber
          emergencyContactRelation
          languages
          otherLanguages
          chronicDiseasesYesNo
          chronicDiseases
          nationality
          motherNationality
          countryOfResidence
          age
        }
      }
    }
  }
`;

export const VALUE_POINT_FIELDS = gql`
  fragment ValuePointFields on ValuePointTranslationType {
    id
    order
    title
    titleEn
    titleAr
  }
`;

export const OUR_STORY_STATISTICAL_ITEM_FIELDS = gql`
  fragment OurStoryStatisticalItemFields on OurStoryStatisticalItemTranslationType {
    id
    title
    titleEn
    titleAr
    value
  }
`;

export const GET_ABOUT_SECTION_DETAILS = gql`
  ${VALUE_POINT_FIELDS}
  ${OUR_STORY_STATISTICAL_ITEM_FIELDS}

  query GetAboutSectionDetails {
    aboutSectionDetails {
      id
      ourStoryEn
      ourStoryAr
      ourStoryImage
      ourTeamPdf

      ourProgramEn
      ourProgramAr

      ourTeamEn
      ourTeamAr

      valuePoints {
        ...ValuePointFields
      }

      ourStoryStatisticalItems {
        ...OurStoryStatisticalItemFields
      }
    }
  }
`;

export const OUR_PROGRAM_FIELDS = gql`
  fragment OurProgramFields on OurProgramTranslationType {
    id
    order
    title
    titleEn
    titleAr
    description
    descriptionEn
    descriptionAr
  }
`;

export const COMPANY_PROFILE_FIELDS = gql`
  fragment CompanyProfileFields on CompanyProfileType {
    id
    nameEn
    nameAr
    descriptionEn
    descriptionAr
    logo
  }
`;

export const GET_OUR_PROGRAM_SECTION_DETAILS = gql`
  ${COMPANY_PROFILE_FIELDS}
  query ourProgramsDetails($year: String) {
    ourProgramsDetails(year: $year) {
      company {
        ...CompanyProfileFields
      }
      createdAt
      id
    }
  }
`;

export const GET_OUR_PROGRAMS_YEARS = gql`
  query ourProgramYears {
    ourProgramYears
  }
`;

export const GET_OUR_VALUE_POINTS = gql`
  query GetOurValuePoints {
    ourValuesPointsList {
      id
      order
      title
      titleEn
      titleAr
      active
    }
  }
`;

export const GET_OUR_STORY_STATISTICAL_ITEMS = gql`
  query GetOurStoryStatisticalItems {
    statisticalItemsList {
      id
      title
      titleEn
      titleAr
      value
      active
    }
  }
`;

export const GET_REGISTRATION_DETAILS = gql`
  query registrationDetails($registrationId: ID) {
    registrationDetails(registrationId: $registrationId) {
      status
      paymentStatus
      valuesYouBelieveIn
      howDidYouKnowAboutTheProgram
      mediaSkillsYesNo
      mediaSkills
      mediaFields
      civilId
      civilIdBase64
      personalImage
      personalImageBase64
      ibanNumber
      tshirtSize
      specialRequest
      programRequirements
      correctInformation
      formType
      assignedJobOpportunity {
        jobTitle
        companyRequest {
          companyNameEn
          companyNameAr
        }
      }
      questionAnswers {
        question
        answer
      }
      participantProfile {
        user {
          id
          email
        }
        participantId
        fullNameEn
        fullNameAr
        civilIdNumber
        dateOfBirth
        gender
        mobileNumber
        guardianMobileNumber
        guardianRelation
        emergencyContactNumber
        emergencyContactRelation
        languages
        otherLanguages
        chronicDiseasesYesNo
        chronicDiseases
        nationality
        motherNationality
        countryOfResidence
        age
      }
    }
  }
`;

export const TSHIRT_SIZE_COUNTERS = gql`
  query tshirtSizeCounters($filters: String, $search: String) {
    tshirtSizeCounters(filters: $filters, search: $search) {
      size
      count
    }
  }
`;

export const ABSENCE_REQUESTS_LIST = gql`
  query absenceRequests($filters: String, $search: String, $first: Int, $skip: Int) {
    absenceRequests(filters: $filters, search: $search, first: $first, skip: $skip) {
      id
      date
      status
      reason
      createdAt
      registration {
        participantProfile {
          participantId
          fullNameAr
          fullNameEn
          mobileNumber
          email
        }
        status
        paymentStatus
        assignedJobOpportunity {
          jobTitle
          jobDescription
          companyRequest {
            companyNameEn
            companyNameAr
          }
        }
      }
    }
  }
`;

export const PRODUCTS_LIST = gql`
  query productsList($active: Boolean) {
    productsList(active: $active) {
      id
      nameEn
      nameAr
      descriptionEn
      descriptionAr
      picture
      availableQuantity
      price
      active
    }
  }
`;

export const ORDERS_LIST = gql`
  query ordersList($filters: String, $search: String, $first: Int, $skip: Int) {
    ordersList(filters: $filters, search: $search, first: $first, skip: $skip) {
      id
      orderId
      status
      paymentStatus
      createdAt
      participant {
        participantId
        fullNameAr
        fullNameEn
        mobileNumber
        email
      }
      orderProducts {
        id
        product {
          picture
          nameAr
          nameEn
          price
        }
        quantity
      }
    }
  }
`;
