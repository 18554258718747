import { Col } from "antd";
import { useState } from "react";

type Props = {
  years: string[];
  currentActiveYear: string | null;
  handleChangeActiveYear: (year: string) => void;
};

const YearSwitcher = ({ years, currentActiveYear, handleChangeActiveYear }: Props) => {
  const [selectedYear, setSelectedYear] = useState<string | null>(currentActiveYear);

  const handleClickYear = (year: string) => {
    setSelectedYear(year);
    handleChangeActiveYear(year);
  };

  return (
    <Col style={{ display: "flex", alignItems: "center", gap: "8px", overflowX: "auto" }}>
      {years.map((year) => (
        <div
          key={year}
          onClick={() => handleClickYear(year)}
          style={{
            padding: "6px 12px",
            cursor: "pointer",
            borderRadius: "6px",
            background: year === selectedYear ? "rgb(45, 165, 149)" : "#f0f0f0",
            color: year === selectedYear ? "#fff" : "rgb(45, 165, 149)",
            fontWeight: year === selectedYear ? "bold" : "normal",
          }}
        >
          {year}
        </div>
      ))}
    </Col>
  );
};

export default YearSwitcher;
