import styled from "styled-components";

const CompanyProfileContainer = styled.div`
  .ant-result-subtitle {
    direction: ltr;
    font-family: "Figtree", sans-serif;
  }

  .react-tel-input .form-control {
    border-radius: 6px;
    width: 100%;
    height: 40px;
    color: #000;
    font-size: 14px;
  }

  .verify-email-btn {
    padding: 0;
    font-size: inherit;
    color: #35b4a7;

    span {
      text-decoration: underline;
    }
  }
`;

export default CompanyProfileContainer;
