import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "antd";

const Picture = ({ obj }) => {
  const [showDescription, setShowDescription] = useState(false);

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <div
      onMouseEnter={() => setShowDescription(true)}
      onMouseLeave={() => setShowDescription(false)}
      onTouchStart={() => setShowDescription(true)}
      onTouchEnd={() => setShowDescription(false)}
    >
      {showDescription ? (
        <Card className="carousel-card">
          {obj["description" + (currentLanguage === "en" ? "En" : "Ar")]}
        </Card>
      ) : (
        <img src={obj.picture} alt={obj.title} className="carousel-image" />
      )}
    </div>
  );
};

export default Picture;
