import styled from "styled-components";

const ProgramsListContainer = styled.div`
  .ant-layout {
    background: #fff;
  }

  .ant-carousel {
    width: 100%;
  }

  .slick-slide.slick-active.slick-current,
  .slick-slide.slick-active {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100%;
    width: 100%;
  }

  // Make arrows visible.
  .ant-carousel .slick-prev,
  .ant-carousel .slick-next {
    position: absolute; // Ensure both arrows are positioned absolutely
    top: 50%;
    transform: translateY(-50%); // Center the arrows vertically
    z-index: 10; // Make sure the arrows are above other elements
  }

  .ant-carousel .slick-prev {
    inset-inline-start: 0;
  }

  .ant-carousel .slick-next {
    inset-inline-end: 0;
    right: 18px;
  }

  .ant-carousel .slick-arrow {
    span {
      width: 40px; // Adjust the size of the arrows
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 20px;
        height: 20px;
        color: #212121;
      }
    }
  }
`;

export default ProgramsListContainer;
