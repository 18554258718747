import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { Layout, Menu, Drawer, Button, Divider, Badge } from "antd";
import {
  MenuOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  LogoutOutlined,
  GlobalOutlined,
  HomeOutlined,
  FormOutlined,
  TableOutlined,
  CalendarOutlined,
} from "@ant-design/icons";

import NavbarStyles from "./styles";

import styled from "styled-components";

import useAuth from "../../auth";
import { useCart } from "../Cart/CartContext";

import enUS from "antd/locale/en_US";
import arEG from "antd/locale/ar_EG";

const { Header } = Layout;

const Navbar = () => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => setVisible(true);
  const closeDrawer = () => setVisible(false);

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const { isAuthenticated, logout, user } = useAuth();
  const { cartCount } = useCart();

  const [locale, setLocal] = useState(currentLanguage === "ar" ? arEG : enUS);

  const handleSwitchLanguage = () => {
    const localeValue = locale === enUS ? arEG : enUS;
    setLocal(localeValue);
    i18n.changeLanguage(currentLanguage === "ar" ? "en" : "ar");
  };

  const subMenuItems = [
    {
      label: t("navbar.rightMenu.homeTab"),
      key: "home",
      icon: <HomeOutlined />,
      onClick: () => {
        navigate("/");
        closeDrawer();
      },
    },
  ];

  if (isAuthenticated && user.userType === "Participant")
    subMenuItems.push(
      {
        label: t("navbar.rightMenu.registrationsHistoryTab"),
        key: "account",
        icon: <CalendarOutlined />,
        onClick: () => {
          navigate(`/history/${user.userID}`);
          closeDrawer();
        },
      },
      {
        label: t("navbar.rightMenu.profileTab"),
        key: "profile",
        icon: <UserOutlined />,
        onClick: () => {
          navigate(`/profile/${user.userID}`);
          closeDrawer();
        },
      },
    );

  if (isAuthenticated && user.userType === "Staff")
    subMenuItems.push({
      label: t("navbar.rightMenu.dashboardTab"),
      key: "dashboard",
      icon: <TableOutlined />,
      onClick: () => {
        navigate("/application-panel");
        closeDrawer();
      },
    });

  if (isAuthenticated && user.userType === "Company")
    subMenuItems.push(
      {
        label: t("navbar.rightMenu.profileTab"),
        key: "profile",
        icon: <UserOutlined />,
        onClick: () => {
          navigate(`/profile/${user.userID}`);
          closeDrawer();
        },
      },
      {
        label: t("navbar.rightMenu.requestInternsTab"),
        key: "requestInterns",
        icon: <FormOutlined />,
        onClick: () => {
          navigate("/request-form");
          closeDrawer();
        },
      },
      {
        label: t("navbar.rightMenu.myRequestsTab"),
        key: "myRequests",
        icon: <TableOutlined />,
        onClick: () => {
          navigate("/requests");
          closeDrawer();
        },
      },
    );

  subMenuItems.push(
    {
      type: "divider",
    },
    {
      label: (
        <span style={{ fontFamily: '"Figtree", sans-serif' }}>
          {currentLanguage === "ar" ? "EN" : "AR"}
        </span>
      ),
      key: "switchLanguage",
      icon: <GlobalOutlined />,
      onClick: handleSwitchLanguage,
    },
    {
      label: t("navbar.rightMenu.logout"),
      key: "logout",
      icon: <LogoutOutlined />,
      onClick: async () => {
        await logout();
        navigate("/");
        closeDrawer();
      },
    },
  );

  const menuItems = isAuthenticated
    ? subMenuItems
    : [
        {
          label: (
            <>
              <Button
                type="text"
                onClick={() => {
                  navigate("/sign-in");
                  closeDrawer();
                }}
                className="signInBtn"
              >
                {t("navbar.rightMenu.signIn")}
              </Button>

              <Divider
                type="vertical"
                className="mx-3"
                style={{ borderWidth: "2px", height: "20px" }}
              />

              <Button
                type="primary"
                onClick={() => {
                  navigate("/user-type");
                  closeDrawer();
                }}
                className="signUpBtn"
              >
                {t("navbar.rightMenu.signUp")}
              </Button>
            </>
          ),
          key: "signIn",
        },
        {
          label: (
            <Button type="secondary" className="english-field" style={{ width: "48px" }}>
              {currentLanguage === "ar" ? "EN" : "AR"}
            </Button>
          ),
          key: "switchLanguage",
          onClick: handleSwitchLanguage,
        },
      ];

  const mobileMenuItems = isAuthenticated
    ? subMenuItems
    : [
        {
          label: (
            <>
              <Button
                type="text"
                onClick={() => {
                  navigate("/sign-in");
                  closeDrawer();
                }}
                className="signInBtn"
              >
                {t("navbar.rightMenu.signIn")}
              </Button>

              <Divider
                type="vertical"
                className="mx-3"
                style={{ borderWidth: "2px", height: "20px" }}
              />

              <Button
                type="primary"
                onClick={() => {
                  navigate("/user-type");
                  closeDrawer();
                }}
                className="signUpBtn"
              >
                {t("navbar.rightMenu.signUp")}
              </Button>
            </>
          ),
          key: "signIn",
        },
        {
          label: (
            <span style={{ fontFamily: '"Figtree", sans-serif' }}>
              {currentLanguage === "ar" ? "EN" : "AR"}
            </span>
          ),
          key: "switchLanguage",
          icon: <GlobalOutlined />,
          onClick: handleSwitchLanguage,
        },
      ];

  return (
    <NavbarStyles>
      <Header>
        {/* Logo */}
        <Link to="/">
          <img src="/logo.svg" alt="Waed Logo" width="80" />
        </Link>

        {/* Desktop Menu (Hidden on Mobile) */}
        {!isAuthenticated && (
          <Menu
            mode="horizontal"
            items={menuItems}
            className="d-none d-lg-flex flex-grow-1 justify-content-end"
            selectedKeys={[]}
          />
        )}

        {/* Right-side buttons (Cart + Menu Button) */}
        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
          {/* Cart Button */}
          <Link to="/cart">
            <Badge count={cartCount} className="cart-badge">
              <Button type="primary" icon={<ShoppingCartOutlined />} style={{ width: "48px" }} />
            </Badge>
          </Link>

          {/* Mobile Menu Button */}
          <Button
            type="text"
            icon={<MenuOutlined />}
            onClick={showDrawer}
            className={isAuthenticated ? "" : "d-lg-none"}
            style={{ width: "48px" }}
          />
        </div>

        {/* Mobile Drawer */}
        <Drawer
          title={isAuthenticated ? <span className="english-field">{user.email}</span> : ""}
          placement="right"
          onClose={closeDrawer}
          open={visible}
        >
          <StyledMenu
            mode="vertical"
            items={mobileMenuItems}
            selectedKeys={[]}
            style={{ borderInlineEnd: "none" }}
          />
        </Drawer>
      </Header>
    </NavbarStyles>
  );
};

export default Navbar;

const StyledMenu = styled(Menu)`
  .ant-menu-item-active {
    background-color: transparent !important; /* Remove background color when active */
  }

  .ant-menu-item:hover {
    background-color: transparent !important; /* Remove background color on hover */
  }

  .ant-menu-item {
    &.ant-menu-item-active {
      background-color: transparent !important; /* Ensure it's transparent when active */
    }
  }
`;
