import { Col, Flex, Layout, Row, Tag, Typography } from "antd";
import { Loading } from "components/Loading";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GetAboutData } from "types/Home";

const { Title, Text } = Typography;
const { Content } = Layout;

type AboutUsSectionProps = {
  data: GetAboutData | undefined;
  loading: boolean;
};

const AboutUsSection = ({ data, loading }: AboutUsSectionProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const isArabic = language === "ar";

  const { aboutSectionDetails } = data || {};

  const valuePointsSortedByOrder = useMemo(
    () => [...(aboutSectionDetails?.valuePoints || [])].sort((a, b) => a.order - b.order),
    [aboutSectionDetails?.valuePoints],
  );

  if (loading || !data) return <Loading />;

  return (
    <Layout
      id="our-story"
      className="layout-default"
      style={{
        paddingBottom: "60px",
        background: "#fff",
      }}
    >
      <Content>
        <Row
          gutter={[24, 40]}
          justify="space-around"
          style={{
            marginTop: "60px",
          }}
        >
          <Col
            style={{
              maxWidth: "700px",
              textAlign: "justify",
              textAlignLast: "center",
            }}
          >
            <Title
              className="mb-4 mt-0"
              style={{
                fontSize: "45px",
                fontWeight: "bolder",
              }}
            >
              {t("home.ourStory.title")}
            </Title>

            <Text
              style={{
                fontSize: "16px",
                color: "rgb(87,91,103)",
              }}
            >
              {isArabic ? aboutSectionDetails?.ourStoryAr : aboutSectionDetails?.ourStoryEn}
            </Text>
          </Col>
        </Row>

        <Row
          gutter={[24, 40]}
          justify="space-around"
          style={{
            marginTop: "60px",
          }}
          className="d-flex align-items-center"
        >
          <Col
            xs={24}
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={aboutSectionDetails?.ourStoryImage} alt="About Us" width="50%" />
          </Col>

          <Col xs={24} md={12}>
            <Flex gap="24px" vertical>
              {valuePointsSortedByOrder.map((item, index) => (
                <Flex key={item.id} gap="24" vertical>
                  <Flex gap="middle" align="center">
                    <Tag
                      style={{
                        borderRadius: "100%",
                        color: "rgb(45,165,149)",
                        backgroundColor: "rgb(231, 243, 241)",
                        borderColor: "#fff",
                        fontSize: "18px",
                        width: "40px",
                        height: "40px",
                      }}
                      className="d-flex justify-content-center"
                    >
                      {index + 1}
                    </Tag>

                    <Flex vertical>
                      <Text
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        {isArabic ? item.titleAr : item.titleEn}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Col>
        </Row>

        <Row
          gutter={[54, 16]}
          justify="center"
          style={{
            marginTop: "60px",
          }}
        >
          {aboutSectionDetails?.ourStoryStatisticalItems.map((item) => (
            <Col key={item.id}>
              <Flex gap="small" align="center" justify="center">
                <Text
                  style={{
                    fontSize: "32px",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item.value}
                </Text>
                <Text
                  style={{
                    fontSize: "16px",
                    color: "rgb(87,91,103)",
                  }}
                >
                  {isArabic ? item.titleAr : item.titleEn}
                </Text>
              </Flex>
            </Col>
          ))}
        </Row>
      </Content>
    </Layout>
  );
};

export default AboutUsSection;
