import { useMutation, useQuery } from "@apollo/client";
import { Button, Col, Form, Input, Layout, Row, Typography } from "antd";
import ApplicationPanelFormContainer from "components/ApplicationPanel/styles";
import { Loading } from "components/Loading";
import { UPDATE_FOOTER_SECTION_MUTATION } from "graphql/manageLandingPage/mutations";
import { GET_FOOTER_SECTION_DETAILS } from "graphql/queries";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { Footer, GetFooterResponse, UpdateFooterResponse } from "types/Home";

const { Title } = Typography;
const { Content } = Layout;

const FooterSectionForm = () => {
  const { t } = useTranslation();

  const {
    loading: footerSectionDetailsLoading,
    data: footerSectionDetailsData,

    refetch: footerSectionDetailsRefetch,
  } = useQuery<GetFooterResponse>(GET_FOOTER_SECTION_DETAILS);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const errorMessageRef = useRef<HTMLDivElement>(null);
  const successMessageRef = useRef<HTMLDivElement>(null);

  const [updateFooterSectionMutation] = useMutation(UPDATE_FOOTER_SECTION_MUTATION, {
    onCompleted: (response: UpdateFooterResponse) => {
      if ("updateFooterSection" in response && response.updateFooterSection) {
        setErrorMessage(null);
        setSuccessMessage(t("dashboard.savedSuccessfullyMessage"));
        scrollToSuccess();
        setTimeout(() => {
          setSuccessMessage(null);
        }, 5000); // Clear success message after 5 seconds
        footerSectionDetailsRefetch();
      } else if ("errors" in response) {
        setErrorMessage(response.errors[0]);
        setSuccessMessage(null);
        scrollToError();
      }
    },
  });

  const onFinish = (values: Footer) => {
    updateFooterSectionMutation({
      variables: { ...values },
    });
  };

  // @ts-expect-error because we dont have they type ValidateErrorEntity exported from antd
  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line no-console
    console.log("Failed:", errorInfo);
  };

  const scrollToError = () => {
    if (errorMessageRef.current && "scrollIntoView" in errorMessageRef.current) {
      (errorMessageRef.current as HTMLElement).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const scrollToSuccess = () => {
    if (successMessageRef.current && "scrollIntoView" in successMessageRef.current) {
      successMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  if (footerSectionDetailsLoading || !footerSectionDetailsData?.footerSectionDetails)
    return <Loading />;

  return (
    <ApplicationPanelFormContainer>
      <Layout className="p-3 p-md-5 m-0">
        <Content>
          <Row gutter={[24, 0]}>
            <Col xs={24}>
              <Title
                className="mb-5 mt-0"
                style={{
                  fontSize: "45px",
                  whiteSpace: "break-spaces",
                }}
              >
                {t("dashboard.sideNav.footerSectionTab")}
              </Title>
            </Col>
          </Row>

          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="row-col"
            scrollToFirstError={true}
            initialValues={footerSectionDetailsData?.footerSectionDetails}
          >
            <div ref={errorMessageRef}></div> {/* Error message ref */}
            {errorMessage && <p className="text-danger english-field">{errorMessage}</p>}
            <div ref={successMessageRef}></div> {/* Success message ref */}
            {successMessage && <p className="text-success">{successMessage}</p>}
            <Row gutter={[24, 0]}>
              <Col xs={12}>
                <Form.Item
                  label={t("dashboard.landingPageSettings.footerSection.email")}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                    },
                  ]}
                >
                  <Input maxLength={255} showCount className="english-field" />
                </Form.Item>
              </Col>

              <Col xs={12}>
                <Form.Item
                  label={t("dashboard.landingPageSettings.footerSection.whatsappNumber")}
                  name="whatsappNumber"
                  rules={[
                    {
                      required: true,
                      message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <PhoneInput
                    inputStyle={{
                      width: "100%",
                      height: "40px",
                      color: "#000",
                      fontSize: "14px",
                      borderRadius: "6px",
                      borderColor: "#57c2b4",
                      boxShadow: "0 0 0 .25rem rgba(13,110,253,.25)",
                    }}
                    containerStyle={{
                      textAlign: "left",
                      direction: "ltr",
                      fontFamily: '"Figtree", sans-serif',
                    }}
                    country="kw"
                    placeholder=""
                    excludeCountries={["il"]}
                    preferredCountries={["kw"]}
                    onChange={(value, data) => {
                      const countryCode = "dialCode" in data ? data.dialCode : "";
                      const countryCodeIndex = value.indexOf(countryCode);
                      const length = countryCode.length;
                      const number = countryCode + "-" + value.slice(countryCodeIndex + length);

                      return number;
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item
                  label={t("dashboard.landingPageSettings.footerSection.briefInEnglish")}
                  name="briefEn"
                  rules={[
                    {
                      required: true,
                      message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                    },
                  ]}
                >
                  <Input.TextArea rows={5} maxLength={255} showCount className="english-field" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item
                  label={t("dashboard.landingPageSettings.footerSection.briefInArabic")}
                  name="briefAr"
                  rules={[
                    {
                      required: true,
                      message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                    },
                  ]}
                >
                  <Input.TextArea rows={5} maxLength={255} showCount className="arabic-field" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                    {t("dashboard.applicationPanelForm.saveBtn")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Content>
      </Layout>
    </ApplicationPanelFormContainer>
  );
};

export default FooterSectionForm;
