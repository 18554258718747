import styled from "styled-components";

const InvoiceContainer = styled.div`
  .ant-descriptions-view,
  .ant-descriptions-view .ant-badge {
    direction: ltr;
    text-align: left;
    font-family: "Figtree", sans-serif;
  }
`;

export default InvoiceContainer;
