import { Modal } from "antd";

import { useTranslation } from "react-i18next";

import InternInformationPDF from "./InternInformationPDF";

const RegistrationResponseModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  registrationID,
  registrationObj,
}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <Modal
      title={
        <>
          <span>{t("dashboard.registrationFormResponseModal.modalTitle")}</span>
          <InternInformationPDF registrationID={registrationID} />
        </>
      }
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      key={registrationID}
      width={800}
      footer={null}
    >
      <ul>
        <li style={{ listStyleType: "square", fontWeight: "bold" }}>
          {t("registrationForm.stepTwo.valuesYouBelieveInField")}
          <ul>
            <li style={{ fontWeight: "normal" }}>{registrationObj.valuesYouBelieveIn || "-"}</li>
          </ul>
        </li>

        <li style={{ listStyleType: "square", fontWeight: "bold" }}>
          {t("registrationForm.stepTwo.howDidYouKnowAboutTheProgramField")}
          <ul>
            <li style={{ fontWeight: "normal" }}>
              {registrationObj.howDidYouKnowAboutTheProgram || "-"}
            </li>
          </ul>
        </li>

        {registrationObj.formType === "Seasonal Form" && (
          <>
            <li style={{ listStyleType: "square", fontWeight: "bold" }}>
              {t("registrationForm.stepTwo.mediaSkillsYesNoField")}
              <ul>
                <li style={{ fontWeight: "normal" }}>
                  {registrationObj.mediaSkillsYesNo
                    ? t(
                        `dashboard.registrationFormResponseModal.${registrationObj.mediaSkillsYesNo}`,
                      )
                    : "-"}
                </li>
              </ul>
            </li>

            <li style={{ listStyleType: "square", fontWeight: "bold" }}>
              {t("registrationForm.stepTwo.mediaSkillsField")}
              <ul>
                <li style={{ fontWeight: "normal" }}>{registrationObj.mediaSkills || "-"}</li>
              </ul>
            </li>

            <li style={{ listStyleType: "square", fontWeight: "bold" }}>
              {t("registrationForm.stepTwo.mediaFieldsField")}
              <ul>
                <li style={{ fontWeight: "normal" }}>
                  {registrationObj.mediaFields
                    ? registrationObj.mediaFields
                        .split(", ") // Split the string into an array based on the delimiter
                        .map((obj) => t(`dashboard.registrationFormResponseModal.${obj}`)) // Translate each option
                        .join(currentLanguage === "ar" ? "، " : ", ") // Join the translated options back into a string
                    : "-"}
                </li>
              </ul>
            </li>
          </>
        )}

        <li style={{ listStyleType: "square", fontWeight: "bold" }}>
          {t("registrationForm.stepTwo.civilIdField")}
          <ul>
            <li style={{ fontWeight: "normal" }}>
              {registrationObj.civilId ? (
                <a
                  style={{ fontFamily: '"Figtree", sans-serif' }}
                  href={registrationObj.civilId}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {registrationObj.civilId}
                </a>
              ) : (
                "-"
              )}
            </li>
          </ul>
        </li>

        <li style={{ listStyleType: "square", fontWeight: "bold" }}>
          {t("registrationForm.stepTwo.personalImageField")}
          <ul>
            <li style={{ fontWeight: "normal" }}>
              {registrationObj.personalImage ? (
                <a
                  style={{ fontFamily: '"Figtree", sans-serif' }}
                  href={registrationObj.personalImage}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {registrationObj.personalImage}
                </a>
              ) : (
                "-"
              )}
            </li>
          </ul>
        </li>

        <li style={{ listStyleType: "square", fontWeight: "bold" }}>
          {t("registrationForm.stepTwo.ibanNumberField")}
          <ul>
            <li style={{ fontWeight: "normal" }}>
              {registrationObj.ibanNumber ? (
                <a
                  style={{ fontFamily: '"Figtree", sans-serif' }}
                  href={registrationObj.ibanNumber}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {registrationObj.ibanNumber}
                </a>
              ) : (
                "-"
              )}
            </li>
          </ul>
        </li>

        <li style={{ listStyleType: "square", fontWeight: "bold" }}>
          {t("registrationForm.stepTwo.tshirtSizeField")}
          <ul>
            <li
              style={{
                fontWeight: "normal",
                fontFamily: registrationObj.tshirtSize ? '"Figtree", sans-serif' : "inherit",
              }}
            >
              {registrationObj.tshirtSize || "-"}
            </li>
          </ul>
        </li>

        <li style={{ listStyleType: "square", fontWeight: "bold" }}>
          {t("registrationForm.stepTwo.specialRequestField")}
          <ul>
            <li style={{ fontWeight: "normal" }}>{registrationObj.specialRequest || "-"}</li>
          </ul>
        </li>

        <li style={{ listStyleType: "square", fontWeight: "bold" }}>
          {t("registrationForm.stepThree.agreeToAttend")}
          <ul>
            <li style={{ fontWeight: "normal" }}>
              {registrationObj.programRequirements ? "✅" : "❎"}
            </li>
          </ul>
        </li>

        <li style={{ listStyleType: "square", fontWeight: "bold" }}>
          {t("registrationForm.stepThree.correctInformation")}
          <ul>
            <li style={{ fontWeight: "normal" }}>
              {registrationObj.correctInformation ? "✅" : "❎"}
            </li>
          </ul>
        </li>
      </ul>
    </Modal>
  );
};

export default RegistrationResponseModal;
