import { TSHIRT_SIZE_COUNTERS } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";
import { Card, Col, Row, Typography } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const TshirtSizeCounters = ({ filterQuery, searchQuery, registrationsDataLoading }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const { data: tshirtSizeCountersData, loading: tshirtSizeCountersDataLoading } = useQuery(
    TSHIRT_SIZE_COUNTERS,
    {
      variables: { filters: filterQuery, search: searchQuery },
    },
  );

  if (tshirtSizeCountersDataLoading || registrationsDataLoading) return null;

  return (
    <>
      <Title level={4} style={{ fontSize: "1.3rem" }}>
        {t("dashboard.registrationsTable.tshirtCounter.title")}
      </Title>

      <Row
        gutter={[16, 16]}
        justify="center"
        style={{
          flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
        }}
      >
        {tshirtSizeCountersData.tshirtSizeCounters.map((obj, index) => (
          <Col lg={6} xs={12} key={index}>
            <StyledCard>
              <CustomTitle>{obj.size}</CustomTitle>
              <Count>{obj.count}</Count>
            </StyledCard>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default TshirtSizeCounters;

const StyledCard = styled(Card)`
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border: 2px solid #fff;
`;

const CustomTitle = styled.div`
  font-family: "Figtree", sans-serif;
  font-size: 20px;
  font-weight: 900;
  color: #35b4a7;
`;

const Count = styled.div`
  font-family: "Figtree", sans-serif;
  font-size: 17px;
  font-weight: 600;
  color: #35b4a7;
`;
