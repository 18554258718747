import React from "react";
import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";
import { GET_TEAM_SECTION_DESCRIPTION, GET_TEAM_MEMBERS_LIST } from "../../../graphql/queries";

import { Layout, Row, Col, Typography } from "antd";

import { Loading } from "../../Loading";
import Member from "./Member";

const { Title, Text } = Typography;
const { Content } = Layout;

const Team = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const { loading: teamSectionDescriptionLoading, data: teamSectionDescriptionData } = useQuery(
    GET_TEAM_SECTION_DESCRIPTION,
  );

  const { loading: teamMembersListLoading, data: teamMembersListData } = useQuery(
    GET_TEAM_MEMBERS_LIST,
    {
      variables: { active: true },
    },
  );

  if (teamSectionDescriptionLoading) return <Loading />;

  return (
    <Layout
      id="our-team"
      className="layout-default"
      style={{
        background: "#fff",
      }}
    >
      <Content
        style={{
          marginTop: "60px",
        }}
      >
        <Row gutter={[24, 40]} justify="space-around">
          <Col
            style={{
              width: "500px",
              textAlign: "center",
            }}
          >
            {/* header */}
            <Title
              className="mb-4 mt-0"
              style={{
                fontSize: "45px",
                fontWeight: "bolder",
              }}
            >
              {t("home.team.title")}
            </Title>
            {/* description */}
            <Text
              style={{
                fontSize: "16px",
              }}
            >
              {
                teamSectionDescriptionData.aboutSectionDetails[
                  "ourTeam" + (currentLanguage === "en" ? "En" : "Ar")
                ]
              }
              {teamSectionDescriptionData.aboutSectionDetails.ourTeamPdf && (
                <span>
                  &nbsp;{t("home.team.moreInfo")}
                  <a
                    href={teamSectionDescriptionData.aboutSectionDetails.ourTeamPdf}
                    style={{ color: "#35b4a7" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("home.team.here")}
                  </a>
                </span>
              )}
            </Text>
          </Col>
        </Row>

        {/* members list */}
        {!teamMembersListLoading && (
          <Row gutter={[24, 40]} justify="space-around" className="mt-5">
            {teamMembersListData.teamMembersList.map((obj) => (
              <Member key={obj.id} obj={obj} />
            ))}
          </Row>
        )}
      </Content>
    </Layout>
  );
};

export default Team;
