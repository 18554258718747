import { useTranslation } from "react-i18next";
import { Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import CompanyCard from "./CompanyCard";

const ProgramCarousel = ({ data }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const dataLength = data.length;

  const customPrevArrow = (
    <div>
      <LeftOutlined />;
    </div>
  );

  const customNextArrow = (
    <div>
      <RightOutlined />
    </div>
  );

  return (
    <Carousel
      arrows
      prevArrow={customPrevArrow}
      nextArrow={customNextArrow}
      dots={false}
      autoplay={false}
      responsive={[
        {
          breakpoint: 992, // For iPads and tablets
          settings: {
            slidesToShow: dataLength < 2 ? dataLength : 2, // Show 3 images at a time
          },
        },
        {
          breakpoint: 768, // For mobile devices
          settings: {
            slidesToShow: 1, // Show 1 image at a time
          },
        },
      ]}
      slidesToShow={data.length < 3 ? data.length : 3} // Default for larger screens
      slidesToScroll={1}
    >
      {data.map((obj) => {
        const company = obj.company;
        const name = company["name" + (currentLanguage === "en" ? "En" : "Ar")];
        const description = company["description" + (currentLanguage === "en" ? "En" : "Ar")];

        return (
          <div className="w-auto px-2 px-md-5" key={obj.id}>
            <CompanyCard title={name} logo={company.logo} description={description} />
          </div>
        );
      })}
    </Carousel>
  );
};

export default ProgramCarousel;
