import { createContext, useContext, useState, useEffect } from "react";

// Create Context
const CartContext = createContext();

// Custom hook to use Cart Context
export const useCart = () => useContext(CartContext);

// Context Provider
export const CartProvider = ({ children }) => {
  const [cartCount, setCartCount] = useState(0);

  // Function to update the cart count
  const updateCartCount = () => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const totalItems = cart.reduce((total, item) => total + item.quantity, 0);
    setCartCount(totalItems);
  };

  // 🔹 Initialize cart count from localStorage on first render
  useEffect(() => {
    updateCartCount();
  }, []);

  return (
    <CartContext.Provider value={{ cartCount, updateCartCount }}>{children}</CartContext.Provider>
  );
};
