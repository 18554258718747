import { AppstoreOutlined, FormOutlined, SettingOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Flex, Menu } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type MenuItem = Required<MenuProps>["items"][number];

const DashboardSideNav = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function getItem(
    label: React.ReactNode,
    key: React.Key, // use key to navigate
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group",
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const items: MenuProps["items"] = [
    getItem(t("dashboard.sideNav.applicationPanelTab"), "/application-panel", <SettingOutlined />),

    getItem(t("dashboard.sideNav.dashboardTab"), "dashboard", <AppstoreOutlined />, [
      getItem(t("dashboard.sideNav.requestsTab"), "/dashboard/requests"),

      getItem(t("dashboard.sideNav.registrationsTab"), "/dashboard/registrations"),

      getItem(t("dashboard.sideNav.attendanceTab"), "/dashboard/attendance"),

      getItem(t("dashboard.sideNav.absenceRequestsTab"), "/dashboard/absence-requests"),

      getItem(t("dashboard.sideNav.ordersTab"), "/dashboard/orders"),
    ]),

    getItem(
      t("dashboard.sideNav.landingPageSettingsTab"),
      "landing-page-settings",
      <FormOutlined />,
      [
        getItem(
          t("dashboard.sideNav.heroSectionTab"),
          "/dashboard/landing-page-settings/hero-section",
        ),

        getItem(
          t("dashboard.sideNav.aboutSectionTab"),
          "/dashboard/landing-page-settings/about-section",
        ),

        getItem(
          t("dashboard.sideNav.participatingEntitiesTab"),
          "/dashboard/landing-page-settings/participating-entities-section",
        ),
        getItem(
          t("dashboard.sideNav.ourValuesTab"),
          "/dashboard/landing-page-settings/our-values-section",
        ),

        getItem(
          t("dashboard.sideNav.ourStatisticsTab"),
          "/dashboard/landing-page-settings/our-statistics-section",
        ),

        getItem(
          t("dashboard.sideNav.sponsorsTab"),
          "/dashboard/landing-page-settings/sponsors-section",
        ),

        getItem(
          t("dashboard.sideNav.socialMediaTab"),
          "/dashboard/landing-page-settings/social-media-section",
        ),

        getItem(
          t("dashboard.sideNav.teamMembersTab"),
          "/dashboard/landing-page-settings/team-members-section",
        ),

        getItem(
          t("dashboard.sideNav.pictureAlbumTab"),
          "/dashboard/landing-page-settings/picture-album-section",
        ),

        getItem(
          t("dashboard.sideNav.productsTab"),
          "/dashboard/landing-page-settings/products-section",
        ),

        getItem(
          t("dashboard.sideNav.footerSectionTab"),
          "/dashboard/landing-page-settings/footer-section",
        ),
      ],
    ),
  ];

  const onClick: MenuProps["onClick"] = (e) => {
    navigate(e.key);
  };

  return (
    <Flex vertical gap={2}>
      <Title level={4} style={{ textAlign: "center", fontSize: "1.5rem" }}>
        {t("dashboard.sideNav.title")}
      </Title>

      <Menu
        onClick={onClick}
        style={{ width: "100%" }}
        defaultOpenKeys={["dashboard", "landing-page-settings"]}
        mode="inline"
        items={items}
      />
    </Flex>
  );
};

export default DashboardSideNav;
