import styled from "styled-components";

const ModalStyles = styled.div`
  .ant-descriptions .ant-descriptions-item-label {
    font-weight: 600;
    font-size: 14px;
    font-family: ${(props) =>
      props.currentLanguage === "ar" ? '"Noto Kufi Arabic", sans-serif' : '"Figtree", sans-serif'};
    color: #000 !important;
  }

  .ant-descriptions .ant-descriptions-item-content {
    font-weight: 400;
    font-size: 14px;
    font-family: ${(props) =>
      props.currentLanguage === "ar" ? '"Noto Kufi Arabic", sans-serif' : '"Figtree", sans-serif'};
  }

  .ant-descriptions .ant-descriptions-title {
    margin-bottom: 0;
  }

  .icon-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px !important;
    height: 40px;
    border-width: 2px;

    svg {
      margin-right: 0;
    }
  }
`;

export default ModalStyles;
