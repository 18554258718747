import { useTranslation } from "react-i18next";
import { useState } from "react";

import styled from "styled-components";

import { Button, Table, Tag, Dropdown, Popconfirm } from "antd";
import { MoreOutlined } from "@ant-design/icons";

import moment from "moment";

import { UPDATE_ORDER_MUTATION } from "../../../graphql/mutations";
import { useMutation } from "@apollo/client";

import OrderDetailsModal from "./OrderDetailsModal";

const paymentStatusColors = {
  Paid: "green",
  Failed: "volcano",
  Pending: "gold",
  Refunded: "purple",
};

const statusColors = {
  Approved: "green",
  Rejected: "volcano",
  Pending: "gold",
  Cancelled: "purple",
  Completed: "cyan",
};

const OrdersTable = ({ data, loading, refetchData }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [isOrderDetailsModalOpen, setOrderDetailsModalOpen] = useState(false);
  const [isInvoiceDetailsModalOpen, setInvoiceDetailsModalOpen] = useState(false);

  const [orderObj, setOrderObj] = useState(null);

  const showOrderDetailsModal = (record) => {
    setOrderDetailsModalOpen(true);
    setOrderObj(record);
  };

  const showInvoiceDetailsModal = (record) => {
    setInvoiceDetailsModalOpen(true);
    setOrderObj(record);
  };

  const cancel = () => {
    // You can handle cancel logic here if needed
    // For example, you might not need to do anything on cancel
  };

  const [updateORderMutation] = useMutation(UPDATE_ORDER_MUTATION, {
    onCompleted: () => {
      refetchData();
    },
  });

  const confirmOrderStatus = (orderID, status) => {
    updateORderMutation({
      variables: { orderId: orderID, status },
    });
  };

  const getActionsItems = (record) => {
    return [
      {
        key: "more-details",
        label: (
          <StyledMenuItem type="text" onClick={() => showOrderDetailsModal(record)}>
            {t("dashboard.ordersTable.viewOrderDetailsBtn")}
          </StyledMenuItem>
        ),
      },
      {
        key: "invoice",
        label: (
          <StyledMenuItem type="text" onClick={() => showInvoiceDetailsModal(record)}>
            {t("dashboard.ordersTable.viewInvoiceDetailsBtn")}
          </StyledMenuItem>
        ),
      },
    ];
  };

  const getStatusManagementItems = (record) => {
    const items = [];
    if (record.status !== "Approved")
      items.push({
        key: "approved",
        label: (
          <Popconfirm
            title={t("dashboard.ordersTable.approveOrderTitle")}
            description={t("dashboard.ordersTable.approveOrderDescription")}
            onConfirm={() => confirmOrderStatus(record.id, "Approved")}
            onCancel={cancel}
            okText={t("dashboard.yes")}
            cancelText={t("dashboard.no")}
          >
            <Button type="primary" size="small" className="px-4" style={{ borderRadius: "50px" }}>
              {t("dashboard.approve")}
            </Button>
          </Popconfirm>
        ),
      });

    if (record.status !== "Rejected")
      items.push({
        key: "rejected",
        label: (
          <Popconfirm
            title={t("dashboard.ordersTable.rejectOrderTitle")}
            description={t("dashboard.ordersTable.rejectOrderDescription")}
            onConfirm={() => confirmOrderStatus(record.id, "Rejected")}
            onCancel={cancel}
            okText={t("dashboard.yes")}
            cancelText={t("dashboard.no")}
          >
            <Button
              type="default"
              danger
              size="small"
              className="px-4"
              style={{ borderRadius: "50px" }}
            >
              {t("dashboard.reject")}
            </Button>
          </Popconfirm>
        ),
      });

    if (record.status !== "Completed")
      items.push({
        key: "completed",
        label: (
          <Popconfirm
            title={t("dashboard.ordersTable.completeOrderTitle")}
            description={t("dashboard.ordersTable.completeOrderDescription")}
            onConfirm={() => confirmOrderStatus(record.id, "Completed")}
            onCancel={cancel}
            okText={t("dashboard.yes")}
            cancelText={t("dashboard.no")}
          >
            <Button
              type="default"
              size="small"
              className="px-4"
              style={{ borderRadius: "50px", borderColor: "#531dab", color: "#531dab" }}
            >
              {t("dashboard.ordersTable.completeOrderTitle")}
            </Button>
          </Popconfirm>
        ),
      });

    if (record.status !== "Cancelled")
      items.push({
        key: "cancelled",
        label: (
          <Popconfirm
            title={t("dashboard.ordersTable.cancelOrderTitle")}
            description={t("dashboard.ordersTable.cancelOrderDescription")}
            onConfirm={() => confirmOrderStatus(record.id, "Cancelled")}
            onCancel={cancel}
            okText={t("dashboard.yes")}
            cancelText={t("dashboard.no")}
          >
            <Button
              type="default"
              size="small"
              className="px-4"
              style={{ borderRadius: "50px", borderColor: "orange", color: "orange" }}
            >
              {t("dashboard.ordersTable.cancelOrderTitle")}
            </Button>
          </Popconfirm>
        ),
      });

    return items;
  };

  const columns = [
    {
      title: t("dashboard.ordersTable.orderID"),
      key: "orderId",
      sorter: (a, b) => a.orderId.localeCompare(b.orderId),
      render: (obj) => <span className="english-field">{obj.orderId}</span>,
    },
    {
      title: t("dashboard.participantID"),
      key: "participantId",
      sorter: (a, b) => a.participant.participantId.localeCompare(b.participant.participantId),
      render: (obj) => <span className="english-field">{obj.participant.participantId}</span>,
    },
    {
      title: t("dashboard.participantName"),
      key: "participantName",
      sorter: (a, b) => a.participant.fullNameEn.length - b.participant.fullNameEn.length,
      render: (obj) => obj.participant["fullName" + (currentLanguage === "en" ? "En" : "Ar")],
    },
    {
      title: t("dashboard.mobileNumber"),
      key: "mobileNumber",
      render: (obj) => (
        <span style={{ unicodeBidi: "embed", direction: "ltr" }}>
          {obj.participant.mobileNumber}
        </span>
      ),
    },
    {
      title: t("dashboard.email"),
      key: "email",
      sorter: (a, b) => a.participant.email.length - b.participant.email.length,
      render: (obj) => <span className="english-field">{obj.participant.email}</span>,
    },
    {
      title: t("dashboard.ordersTable.orderedAt"),
      key: "createdAt",
      dataIndex: "createdAt",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (createdAt) => moment(createdAt).format("DD-MM-YYYY"),
    },
    {
      title: t("dashboard.paymentStatus"),
      key: "paymentStatus",
      dataIndex: "paymentStatus",
      render: (paymentStatus) => (
        <Tag color={paymentStatusColors[paymentStatus]} key={paymentStatus}>
          {t(`dashboard.${paymentStatus.toLowerCase()}`).toUpperCase()}
        </Tag>
      ),
    },
    {
      title: t("dashboard.status"),
      key: "status",
      dataIndex: "status",
      render: (status) => (
        <Tag color={statusColors[status]} key={status}>
          {t(`dashboard.${status.toLowerCase()}`).toUpperCase()}
        </Tag>
      ),
    },
    {
      title: t("dashboard.ordersTable.manageStatus"),
      key: "action",
      render: (record) => {
        if (record.status !== "Pending") return "-";
        const items = getStatusManagementItems(record);
        return (
          <StyledDropdown menu={{ items }} placement="bottomLeft" trigger={["click"]}>
            <StyledMenuIcon icon={<MoreOutlined />}></StyledMenuIcon>
          </StyledDropdown>
        );
      },
    },
    {
      title: t("dashboard.action"),
      key: "action",
      render: (record) => {
        const items = getActionsItems(record);
        return (
          <StyledDropdown menu={{ items }} placement="bottomLeft" trigger={["click"]}>
            <StyledMenuIcon icon={<MoreOutlined />}></StyledMenuIcon>
          </StyledDropdown>
        );
      },
    },
  ];

  return (
    <div style={{ overflowX: "clip" }}>
      <Table
        columns={columns}
        pagination={{ position: ["bottomRight"] }}
        dataSource={data.map((item) => ({ ...item, key: item.id }))}
        scroll={{ x: true }}
        loading={loading}
      />

      {orderObj && (
        <OrderDetailsModal
          isModalOpen={isOrderDetailsModalOpen}
          handleOk={() => setOrderDetailsModalOpen(false)}
          handleCancel={() => setOrderDetailsModalOpen(false)}
          orderObj={orderObj}
        />
      )}
    </div>
  );
};

export default OrdersTable;

const StyledDropdown = styled(Dropdown)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px !important;
  height: 40px;
  border: none;
  box-shadow: none;

  svg {
    margin-right: 0;
  }
`;

const StyledMenuItem = styled(Button)`
  box-shadow: none;
  border-radius: none;

  &:hover {
    background: none !important;
  }
`;

const StyledMenuIcon = styled(Button)`
  border-radius: 50px;

  &:hover {
    background: #35b4a7 !important;

    svg {
      color: #fff;
    }
  }
`;
